<template>
  <div class="eval">
    <div class="topBar">
      <van-nav-bar
        title="学生自我评价"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
      />
    </div>

    <div class="content">
      <div v-html="studentEvalContent"></div>
      <div class="picBox" v-if="EvalImgPath">
        <van-image width="100%" height="100%" :src="prefix(EvalImgPath)" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { apiCaseStuEvaInfo } from "@/service/teacher.js";
import { prefix } from "@/common/js/utils.js";
import { ref } from "vue";

const router = useRouter();
const route = useRoute();

let studentEvalContent = ref("");
let EvalImgPath = ref("");

const onClickLeft = () => {
  router.go(-1);
};

const studentEval = async () => {
  let res = await apiCaseStuEvaInfo({
    case_id: route.query.case_id,
    studentsNo: route.query.No,
  });
  if (res) {
    studentEvalContent.value = res.data.evaluate;
    EvalImgPath.value = res.data.image_path;
  }
};
studentEval();
</script>
<style lang="less" scoped>
.content {
  padding: 10px;
}

.picBox {
  width: 80%;
  margin: auto;
  margin-top: 20px;
}
</style>
